import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import messages from '@/components/layout/LayoutWrapper/messages';
import { useAnalytics } from '@/hooks/analytics';
import { useComplianceLevelState, useUserState } from '@/hooks/store';
import { NO_RESTRICTIONS } from '@/store/complianceLevel/types';
import { getCookie } from '@/utils/cookies';
import getUrl from '@/utils/getUrl';
import { CALM_USER_ID } from '@/utils/privacyCookies';

import { LayoutProps, SeoProps } from './types';

const Layout = ({
	children,
	pageName,
	customSeo,
	title = 'Calm - The #1 App for Meditation and Sleep',
}: LayoutProps) => {
	const router = useRouter();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const complianceLevel = useComplianceLevelState();
	const user = useUserState();

	const setupSeo = (): SeoProps => {
		return {
			title: customSeo?.title ?? formatMessage(messages.homepageTitle),
			primaryDescription: customSeo?.primaryDescription ?? formatMessage(messages.homepagePrimaryDescription),
			description: customSeo?.description ?? formatMessage(messages.homepageDescription),
			imageUrl: customSeo?.imageUrl ?? `${process.env.NEXT_PUBLIC_WWW_URL}/_n/images/social/calm-meta.png`,
			url: customSeo?.url ?? `${getUrl('www') as string}${router.asPath}`,
		};
	};

	const analyticsPageName = pageName || router.pathname;
	const userId = user?.id ?? getCookie(CALM_USER_ID);
	useEffect(() => {
		logEvent({
			eventName: 'Page : Viewed',
			eventProps: {
				page_name: analyticsPageName,
				calm_user_id: userId || null,
			},
		});
	}, [logEvent, analyticsPageName, userId]);

	return (
		<>
			<Head>
				<meta property="calm:repo" content="monorepo" data-testid="monorepo" />
				{complianceLevel === NO_RESTRICTIONS && (
					<>
						<meta property="fb:app_id" content="1592594007691679" />
						<meta name="p:domain_verify" content="3197272a4fe2efb4c260cf3df62be451" />
					</>
				)}
				<meta name="Description" content={setupSeo().primaryDescription} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={setupSeo().title} />
				<meta property="og:image" content={setupSeo().imageUrl} />
				<meta property="og:url" content={setupSeo().url} />
				<meta property="og:description" content={setupSeo().description} />
				<meta property="twitter:site" content="@calm" />
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:title" content={setupSeo().title} />
				<meta property="twitter:image" content={`${setupSeo().imageUrl}`} />
				<meta property="twitter:description" content={setupSeo().description} />
				<title>{title}</title>
			</Head>
			{children}
		</>
	);
};

export default Layout;
